import React from "react";

export const PrivacyPolicy = () => {
  return (
    <div
      style={{
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        padding: "4rem",
      }}
    >
      <h2>Privacy Policy</h2>
      <h3>Finding Metro - Stockholm</h3>
      <p>
        Your privacy is important to us. It is Digtek Media AB's policy to
        respect your privacy regarding information collection from you through
        our app, Finding Metro.
      </p>
      <p>We never store any personal information from you.</p>
      <p>
        All data used by the app, including Positioning data, are not shared
        with anyone and never leaves your device.
      </p>
      <p>
        We don’t collect or share any personally identifying information
        publicly or with third-parties.
      </p>
      <p>
        Your continued use of our app will be regarded as acceptance of our
        practices around privacy and personal information. If you have any
        questions about how we handle user data and personal information, feel
        free to contact us.
      </p>
      <p>This policy is effective as of 3 December 2020.</p>
    </div>
  );
};

import React from "react";
import "./App.css";
import logo from "./digtek-logo.png";
import { PrivacyPolicy2Review } from "./PrivacyPolicy2reviewApp";
import { PrivacyPolicy as PrivacyPolicyMetroNow } from "./PrivacyPolicyMetroNow";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>

      <div>
        {window.location.pathname === "/privacy-policy-metro-now" && (
          <PrivacyPolicyMetroNow />
        )}
        {window.location.pathname === "/privacy-policy-2review" && (
          <PrivacyPolicy2Review />
        )}
      </div>
      <div className="Content">
        <p>Optimistic Updates AB</p>
        <p>Software development & consulting</p>
        <p>
          digtek<span style={{ display: "none" }}>spamfilter</span>
          media@gmail.com
        </p>
      </div>
    </div>
  );
}

export default App;
